/* //////////////////// BACKGROUND //////////////////// */
.bg-base-white{
    background: #f5f5f5;
}

/* //////////////////// BOX SHADOW //////////////////// */
.shadow-inset-1{
    -webkit-box-shadow: rgb(255, 255, 255) 3px 3px 6px 0px inset, rgba(0, 0, 0, 0.133) 3px 3px 6px 0px;
            box-shadow: rgb(255, 255, 255) 3px 3px 6px 0px inset, rgba(0, 0, 0, 0.133) 3px 3px 6px 0px;
}

.shadow-inset-2{
    -webkit-box-shadow: rgb(0 0 0 / 18%) 3px 3px 4px 0px inset, rgb(183 125 46 / 17%) 3px 3px 3px 0px;
            box-shadow: rgb(0 0 0 / 18%) 3px 3px 4px 0px inset, rgb(183 125 46 / 17%) 3px 3px 3px 0px;
}

/* //////////////////// HOVER EFFECT //////////////////// */
.translateY {
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.translateY:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.translateX {
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.translateX:hover {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    transform: translateX(5px);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}